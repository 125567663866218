









































































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

import InfoBlock from './components/InfoBlock.vue'
import CommentBlock from './components/CommentBlock.vue'

@Component({
  components: {
    InfoBlock,
    CommentBlock
  },
})
export default class Member extends Vue {
  get isH5() {
    return AppModule.isH5
  }

  get images() {
    return [
      'https://spics.inctrip.com/881f55d4c202982d141a10648984e6cb.png',
      'https://spics.inctrip.com/0620eefa871603339c03a977b1e2a902.png',
      'https://spics.inctrip.com/c747f3c006e8baec5a9cec967ff4975b.png',
      'https://spics.inctrip.com/0cfc3e9089f9353a973a5185b2afe3fc.png'
    ]
  }
}
